import React, {useContext, useEffect} from 'react';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import * as appContext from '../../../AppContext';
import ReadMoreText from '../../../ReadMoreText';
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../../helpers/MethodHelper";
import Checkers from "../../../../helpers/class/Checkers";
import {minHeight} from "@mui/system";
import ArticleTypes from "../../articles/ArticleTypes";
import Link from "next/link";

export default function CollectionsSection ({ collectionsList, app, enableReadMore, builder, app_data }) {

  const builderCollectionsViewType = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_collections_view_type')
  );

  const customViewType = Checkers.isValidPositiveNumber(builderCollectionsViewType)

  const builderCollectionsBackgroundColor = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_collections_background_color')
  );

  const showCollectionsInHomepage = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_collections_show_in_homepage')
  );

  const showCollectionsTitle = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_collections_title')
  );

  const showCollectionsSubtitle = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_collections_subtitle')
  );

  const pageContext = useContext(appContext.page);

  const collectionItemsToShow = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_collections_items')
  );

  function sliderPerViewHandler() {
    if (pageContext.isMobile) {
      return 1;
    } else {
      return collectionItemsToShow;
    }
  }

  const textColor = () => {
    if (app.builder.builder_homepage_collections_dark_mode != undefined && app.builder.builder_homepage_collections_dark_mode != 0) {
      return "text-light-color"
    }

    return ""
  }

  const prepareCollections = () => {
    const articles = [];

    // console.log("collectionsList", collectionsList.collections)

    Checkers.isValidArray(collectionsList.collections) && collectionsList.collections.forEach( (item, index) => {
      // console.log("collectionsList", item, index)

      const tempString1 = item.title
      const tempString2 = item.properties_count + " villas"

      let articleItem = {
        id:  item.id,
        title: builderCollectionsViewType == 403 || builderCollectionsViewType == 400 || builderCollectionsViewType == 404 || builderCollectionsViewType == 405 || builderCollectionsViewType == 406 ? tempString2 : tempString1,
        subtitle: builderCollectionsViewType == 403 || builderCollectionsViewType == 400 || builderCollectionsViewType == 404 || builderCollectionsViewType == 405 || builderCollectionsViewType == 406 ? tempString1 : tempString2,
        // body: item.properties_count + " villas",
        // description: item.properties_count + " villas",
        loggiaUrl: '/properties/index?collections=' + item.id,
        redirect_url: '/properties/index?collections=' + item.id,
        image: item.image_thumb,
        has_description: 1
      }

      articles.push(articleItem)
    })

    // {
    //   "id": 741,
    //     "title": "Car, bike, bicycle rental",
    //     "body": "Book your vehicle to explore Crete!",
    //     "description": "<p dir=\"ltr\">Explore the island and its beautiful beaches at your own leisure. Make the most of this experience by exploring the area, on a bicycle, on a motorbike or by car. For destinations that haven’t been discovered, that hide below steep hills and lush trails along the rich coastline of the island of Crete, a rented car is the best way for you to get to know the place better. We provide you with every possible transport that can cope with your necessities and preferences.</p><p dir=\"ltr\">We want to make sure you have an amazing experience on your holiday. So, whether you want to drive through the mountains to marvel at the spectacular views, grab your bucket and spade and make sandcastles on the beach, or just a quick and easy trip to the supermarket for the essentials, hiring a car gives you the flexibility and freedom to do just that.&nbsp;</p><p dir=\"ltr\">Renting a car is essential when choosing a villa. It gives you the opportunity to explore and discover the hidden treasures of the regions of Crete and organize your program as you wish. The Holiways team can provide you with the vehicle of your choice, offering our customers a wide range of vehicle models available to meet their personal requirements.</p><p dir=\"ltr\">Don’t hesitate to trust us and our services for more tranquil and without-worried vacations.</p><p><b>Type of vehicle:</b><br>Car renting (Sedan, Minivan, SUV, Cabrio)<br>Luxury car<br>Scooter|<br>Motorbike<br>Bike<br>Quad bikes<br>Other vehicles of your preference</p><p><b>Benefits:</b><br>Professional partners<br>Liable vehicles<br>Arranged to pick up/drop off time<br>Mobility during your holidays<br>Discover more. Travellers are limited when travelling around with public transport<br>Feel like a local</p>",
    //     "image": "https://cdn.loggia.gr/lodgeContent/75f07ca88289cf1e72c66b478d3adefd.jpg",
    //     "loggiaUrl": "/car-rental",
    //     "has_description": 1
    // }

    return articles
  }


  return (
      showCollectionsInHomepage == 1 ?
          <>
            <section id="properties-collections"
                     className={((!Checkers.isHexColor(builderCollectionsBackgroundColor) ? "bg-white" : "") + (customViewType ? " p-b-80" : ""))}
                     style={{background: Checkers.isHexColor(builderCollectionsBackgroundColor) ? (builderCollectionsBackgroundColor) : ''}}>

              {
                collectionsList.title != null && collectionsList.title.length > 0 ?
                    <>
                      {/*<div className={'btn-design-color'} dangerouslySetInnerHTML={{__html: collectionsList.title}}/>*/}
                    </>
                    :
                    <>
                      <div className={'pt-5 pb-4 p-t-100'}>
                        <section className="container">
                          <div className="container text-center sm-p-t-20">
                            {
                              showCollectionsTitle != null && showCollectionsTitle.length > 0 ?
                                  <h2 className={"mt-1 fw-normal m-b-0 " + textColor()}>{showCollectionsTitle}</h2>
                                  :
                                  <></>
                            }
                            {
                              showCollectionsSubtitle != null && showCollectionsSubtitle.length > 0 ?
                                  <h4 className={"block-title mb-0 m-t-0 " + textColor()}>{showCollectionsSubtitle}</h4>
                                  :
                                  <></>
                            }
                          </div>
                        </section>
                      </div>
                    </>
              }

              {
                customViewType ?
                    <>
                      <ArticleTypes
                          type={'collections'}
                          textColor={textColor()}
                          backgroundColor={''}
                          content={''}
                          articles={prepareCollections()}
                          app_data={app_data}
                          builder={builder}
                          viewType={builderCollectionsViewType}
                          articlesCategories={null}
                          articlesLimit={null}
                          itemClassName={'design-box-collection'}
                          slidesPerView={collectionItemsToShow}
                      />
                    </>
                    :
                    <>
                      <div className={"container "}>

                        <div className="panel panel-transparent">

                          <div className="panel-body p-t-20 p-l-20 p-r-20">
                            <div className="row clearfix p-b-40 dot-navigation">
                              <>
                                <Swiper
                                    className="p-b-40"
                                    speed={1000}
                                    loop={true}
                                    navigation={false}
                                    slidesPerView={sliderPerViewHandler()}
                                    autoplay={false}
                                    spaceBetween={20}
                                    pagination={{
                                      clickable: true,
                                    }}
                                >
                                  {
                                    collectionsList.collections.map((collection, key) => {
                                      return (
                                          <SwiperSlide key={key}>
                                            {/*<div className={"col-sm-6"} key={key}>*/}
                                            <div className="row clearfix m-b-5 design-box-collection">
                                              <div className="col-sm-12">
                                                <div className="card social-card share full-width" style={{
                                                  // border: "none",
                                                  // borderRadius: "10px",
                                                  // boxShadow: "0px 0px 8px #CCC",
                                                  marginLeft: "1px",
                                                  overflow: "hidden"
                                                }}>
                                                  {/*style={'border:none; border-radius:10px; box-shadow: 0px 0px 8px #CCC;'}*/}
                                                  <div className="card-content">
                                                    <a href={"properties/index?collections=" + collection.id}>
                                                      <img alt="Social post" src={collection.image_thumb}/>
                                                      {/*  style="border-radius: 10px 10px 0px 0px;"*/}
                                                    </a>
                                                  </div>
                                                  <div className="card-footer clearfix">
                                                    <div className="text-uppercase hint-text bold small-text">
                                                      {collection.properties_count}+ properties
                                                    </div>
                                                    <p className="bold">
                                                      <a href={"properties/index?collections=" + collection.id}
                                                         className="text-master">
                                                        {collection.title}
                                                      </a>
                                                    </p>
                                                    <div className="m-b-30">
                                                      {
                                                        enableReadMore ?
                                                            <ReadMoreText letters={120} title={collection.title}
                                                                          text={collection.description}/>
                                                            :
                                                            <>
                                                              <div className={'container'}>
                                                                <p dangerouslySetInnerHTML={{__html: collection.description}}/>
                                                              </div>
                                                            </>
                                                      }
                                                    </div>
                                                    <div className="m-b-30">
                                                      <a href={"properties/index?collections=" + collection.id}
                                                         className={"btn-design-color btn-search-color btn btn-primary w-100"}>View
                                                        Collection</a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/*</div>*/}
                                          </SwiperSlide>
                                      )
                                    })
                                  }
                                </Swiper>
                              </>
                            </div>
                          </div>

                        </div>

                      </div>
                    </>
              }

              <>
              {
                collectionsList != null && collectionsList.hasOwnProperty('collections') && Checkers.isValidArray(collectionsList.collections) ?
                    <>
                      <div className="container text-center p-t-40 p-b-40">
                        <div className="row justify-content-center">
                          <div className="col-auto text-center">
                            {
                              <Link
                                  href={"/properties/all"}>
                                <a className="btn btn-primary btn-design-color p-4 p-t-10 p-b-10">
                                  See all villa collections
                                </a>
                              </Link>
                            }
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    <>
                    </>
              }
              </>

            </section>
          </>
          :
          <>
          </>
  )
}